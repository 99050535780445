<template>
    <div>
        <b-container fluid>
        <b-row>
            <b-col md="12">
                <OrdersList :orders-type="$route.params.type"></OrdersList>
            </b-col>
        </b-row>
        </b-container>
    </div>
</template>

<script>
import OrdersList from '@/components/orders/OrdersList'
export default {
  name: 'Orders',
  components: { OrdersList }
}
</script>

<style scoped>

</style>
